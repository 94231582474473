(function ($) {
  let initial_indicator = $(".row-desktop .nav-tabs .nav-item:first-child .tab-indicator");
  let initial_tab = $(".row-desktop .nav-tabs .nav-item:first-child");

  let tabs_screensize_state = 'desktop';


  function loop_through_descriptive_tabs_resize_mobile() {
    $('section.descriptive-tabs').each(function () {
      initial_indicator = $(this).find(".row-desktop .nav-tabs .nav-item:first-child .tab-indicator");
      initial_tab = $(this).find(".row-desktop .nav-tabs .nav-item:first-child");
      var parentOffsetMobileTopInitial = $(this).find(".row-desktop .nav-tabs .nav-item").offset().top;
      var parentOffsetMobileInitial = $(this).find('button.nav-link.active').offset().top;
      initial_indicator.attr(
        "style",
        "top:" + (parentOffsetMobileInitial - parentOffsetMobileTopInitial) + "px;" + "height:" + $(this).find('button.nav-link.active').outerHeight(true) + "px;"
      );
    });
  }

  function loop_through_descriptive_tabs_resize_desktop() {
    $('section.descriptive-tabs').each(function () {
      initial_indicator = $(this).find(".row-desktop .nav-tabs .nav-item:first-child .tab-indicator");
      initial_tab = $(this).find(".row-desktop .nav-tabs .nav-item:first-child");
      var parentOffsetDesktopLeftInitial = $(this).find(".row-desktop .nav-tabs .nav-item").offset().left;
      var parentOffsetDesktoplInitial = $(this).find('button.nav-link.active').offset().left;
      initial_indicator.attr(
        "style",
        "left:" + (parentOffsetDesktoplInitial - parentOffsetDesktopLeftInitial) + "px;" + "width:" + initial_tab.outerWidth(true) + "px;"
      );
    });
  }

  function manipulate_screensize_for_tabs() {
    if (window.innerWidth >= 992) {
      let size = 'desktop';
      loop_through_descriptive_tabs_resize_desktop();
      return size;
    } else {
      let size = 'mobile';
      loop_through_descriptive_tabs_resize_mobile();
      return size;
    }
  }

  tabs_screensize_state = manipulate_screensize_for_tabs();

  $(window).resize(function (e) {
    tabs_screensize_state = manipulate_screensize_for_tabs();
  });

  if (tabs_screensize_state === 'desktop') {
    initial_indicator.attr(
      "style",
      "left:" + 0 + "px;" + "width:" + initial_tab.outerWidth(true) + "px;"
    );
  }

  $(".row-desktop .nav-tabs li button").click(function (e) {
    if (tabs_screensize_state === 'mobile') {
      // $(this).parent().parent().parent().parent().find('.tab-content');
      $('html, body').animate({
        scrollTop: $(this).parent().parent().parent().parent().find('.tab-content').offset().top - 200
      }, 2000);
    }


    if (tabs_screensize_state === 'desktop') {
      var parentOffset = $(this).parent().parent().find('.nav-item').offset().left;//$(".row-desktop .nav-tabs .nav-item").offset().left;
      var indicator = $(this).parent().parent().find('.nav-item:first-child .tab-indicator'); //$(".row-desktop .nav-tabs .nav-item:first-child .tab-indicator");

      var clickedOffset = $(this).offset().left;
      indicator.attr(
        "style",
        "left:" +
        (clickedOffset - parentOffset) +
        "px;" +
        "width:" +
        $(this).outerWidth(true) +
        "px;"
      );
    } else {
      var parentOffsetMobile = $(this).parent().parent().find('.nav-item').offset().top;//$(".row-desktop .nav-tabs .nav-item").offset().top;
      var indicatorMobile = $(this).parent().parent().find('.nav-item:first-child .tab-indicator'); //$(".row-desktop .nav-tabs .nav-item:first-child .tab-indicator");
      var clickedOffsetMobile = $(this).offset().top;
      indicatorMobile.attr(
        "style",
        "top:" +
        ((clickedOffsetMobile - parentOffsetMobile) - 3) +
        "px;" + "height:" + $(this).outerHeight(true) + "px;"
      );
    }

  });
})(jQuery);
