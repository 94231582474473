(function ($) {
  $(".side-cta-screen-open").click(function () {
    $(".side-cta-screen").addClass("show");
  });
  $(".side-cta-screen-close").click(function () {
    $(this).blur();
    $(".side-cta-screen").removeClass("show");
  });
  $(document).keyup(function (e) {
    if (e.key === "Escape") {
      if ($(".side-cta-screen").hasClass("show"))
        $(".side-cta-screen").removeClass("show");
    }
  });
})(jQuery);
