(function ($) {
  $(document).ready(function () {
    const $mobile_menu = $(".mobile_menu");
    $(".header__toggle_mobile_menu").click(function () {
      if ($(this).hasClass("open")) {
        $mobile_menu.fadeIn("fast");
      } else {
        $mobile_menu.fadeOut("fast");
      }
    });
  });
})(jQuery);
