(function ($) {
  if (!$("body").hasClass("page-template-template-cart")) return;
  function addTheConfigurations(configurations) {
    let configurationsString = "";
    configurations.each(function () {
      let configurationName = $(this)
        .find(
          ".cart-items-item-information-configurations-configuration-cfg_name"
        )
        .text().trim();
      let theConfiguration = $(this)
        .find(".cart-items-item-information-configurations-configuration-cfg")
        .text().trim();
      configurationsString += `${configurationName}: ${theConfiguration}\n`;
    });
    return configurationsString;
  }

  function edit_the_order() {
    let theOrderString = "// ORDER //\n\n";
    $(".cart-items-item").each(function (idx) {
      theOrderString += `Cart Item #${idx}\n`;
      theOrderString += `Product ID: ${$(this).data("id")}\n`;
      theOrderString += `Product Permalink: ${$(this).data("permalink")}\n`;
      theOrderString += `Product Title: ${$(this)
        .find(".cart-items-item-information-title")
        .text().trim()}\n`;
      theOrderString += `Product Model Number: ${$(this)
        .find(".cart-items-item-information-model_number")
        .text().trim()}\n\n`;
      theOrderString += "Configurations:\n";
      theOrderString += addTheConfigurations(
        $(this).find(
          ".cart-items-item-information-configurations-configuration"
        )
      );
      theOrderString += "--\n\n";
    });
    $("#input_2_10").text(theOrderString);
    return theOrderString;
  }
  let viewCustomizationsButton = $(
    "button.cart-items-item-information-configurations-toggle"
  );
  // add order items to 'order' textarea#input_2_10
  let theOrderString = edit_the_order();

  viewCustomizationsButton.click(function () {
    $(this)
      .parent()
      .find(".cart-items-item-information-configurations-configuration")
      .toggleClass("hidden");
    if (
      !$(this)
        .parent()
        .find(".cart-items-item-information-configurations-configuration")
        .hasClass("hidden")
    )
      $(this)
        .parent()
        .find(".cart-items-item-information-configurations-configuration")
        .slideDown();
    else
      $(this)
        .parent()
        .find(".cart-items-item-information-configurations-configuration")
        .slideUp();
  });

  // Ajax to delete cart item
  $("button.cart-items-item-delete").click(function () {
    let productIdToDelete = $(this).data("id");
    $(this).find(".close-icon").fadeOut(200);
    setTimeout(() => {
      $(this).find(".svg-loading").fadeIn(400);
    }, 200);
    let postData = {
      action: "simplistics_remove_from_cart",
      nonce: $(this).data("nonce"),
      id: productIdToDelete,
    };

    let thisIsTheParent = $(this);

    $.post($(this).data("ajaxurl"), postData, (res) => {
      let response = JSON.parse(res);
      if (response.success) {
        thisIsTheParent
          .parent()
          .animate({ height: "toggle", opacity: "toggle" }, 800);
        let theOrderString = edit_the_order();
        setTimeout(() => {
          thisIsTheParent.parent().remove();
          if (!$(".cart-items-item").length) {
            $("h4.empty-cart").fadeIn(400);
            $(".cart-items").fadeOut(400);
          }
        }, 900);
      } else {
      }
    });
  }); 
  $(document).on("gform_confirmation_loaded", function (event, formId) {
    if (formId === 2) {
      $("body").addClass("submitted");
      $(".hide-me-after-submit").fadeOut('slow');
      $(".gf-form h3.title").html(
        "Your request has been<br><em>successfully submitted.</em>"
      );
      $(".cart-items-item-delete").fadeOut(200);
    }
  });
})(jQuery);
