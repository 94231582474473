//add-to-cart
(function ($) {
  function decodeHtml(str) {
    const map = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#039;': "'"
    };
    return str.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, function (m) {
      return map[m];
    });
  }
  let addToCartButton = $("button.add-to-cart");
  //   if (addToCartButton.length < 1) return;
  //Ajax Call Start
  addToCartButton.click(function () {
    $(this).prop('disabled', true);
    let postData = {
      action: "simplistics_add_to_cart",
      nonce: $(this).data("nonce"),
      product_info: {
        configurations: {},
      },
    };
    postData.product_info.id = $(".product-information").data("id");
    postData.product_info.title = $(".product-information-title > a").text();
    let manufacturer = $('.product-information .product-information-manufacturer');
    if (manufacturer.length) {
      manufacturer = manufacturer.text().trim();
    }
    // postData.product_info.manufacturer = manufacturer;
    postData.product_info.model_number = $(
      ".product-information-model-number"
    ).data("modelnumber");
    $(".product-information-configuration .mainfield select").each(function () {
      postData.product_info.configurations[$(this).attr("id")] = $(this).val();
      let name = $(this)[0].name;
      let label = $(this).find('option:selected')[0].label;
      // Second level textfields
      $(`.mainfield-optional-textboxes input[data-belongs_to='${$(this).attr("id")}']`).each(function () {
        if ($(this).hasClass('shown')) {
          var correspondingsubfieldlabel = $(`label[data-correspondingsubfieldlabel="${$(this).data('correspondingsubfield').replace(/['"]/g, '\\$&')}"]`).text();
          postData.product_info.configurations[`&#8627; ${correspondingsubfieldlabel}`] = ($(this).val() && $(this).val() !== "") ? $(this).val() : "N/A";
        }
      });

      // $(`.subfields[data-label='${name}'][data-mainfield='${decodeHtml(label)}'] select.level_two`).each(function () {
      //   var second_level_label = $(this).data('label');
      //   $(`.subfields[data-label='${name}'][data-mainfield='${decodeHtml(label)}'] select.level_three[data-belongs_to='${second_level_label}']`).each(function () {
      //     console.log($(this).data('label'));
      //   });
      // });
      $(`.subfields[data-label='${name}'][data-mainfield='${label.replace(/['"]/g, '\\$&')}'] select.level_two`).each(function () {
        var second_level_label = $(this).data('label');
        let subfieldVal = $(this).find('option:selected').val();
        // let subfieldName = $(`label[for='${$(this)[0].label}']`).text();
        postData.product_info.configurations[`&#8627; subfield_${$(this).data('label')}`] = subfieldVal;

        // Second level textfields
        $(`.subfields[data-label='${name}'][data-mainfield='${label.replace(/['"]/g, '\\$&')}'] .subfields-field-subfields-optional input[data-belongs_to='${second_level_label}']`).each(function () {
          if ($(this).hasClass('shown')) {
            var correspondingsubfieldlabel = $(`label[data-correspondingsubfieldlabel="${$(this).data('correspondingsubfield')}"]`).text();
            postData.product_info.configurations[`&#8627; &#8627; ${correspondingsubfieldlabel}`] = ($(this).val() && $(this).val() !== "") ? $(this).val() : "N/A";
          }
        });
        // Third Level subfields
        $(`.subfields[data-label='${name}'][data-mainfield='${label.replace(/['"]/g, '\\$&')}'] select.level_three[data-belongs_to='${second_level_label}']`).each(function () {
          // two parents up, check if 
          let subfieldVal = $(this).find('option:selected').val();
          // let subfieldName = $(`label[for='${$(this)[0].label}']`).text();
          if (!($(this).parent().parent().css('display') === 'none'))
            postData.product_info.configurations[`&#8627; &#8627; subfield_${$(this).data('label')}`] = subfieldVal;
        });
      });
    });

    const lowercaseManufacturer = manufacturer.toLowerCase();
    if (
      lowercaseManufacturer === "unimeasure" ||
      lowercaseManufacturer === "flowmetrics" ||
      lowercaseManufacturer === "incon" ||
      lowercaseManufacturer === "gmi" ||
      lowercaseManufacturer === "isotech" ||
      lowercaseManufacturer === "m-system" ||
      lowercaseManufacturer === "michell" ||
      lowercaseManufacturer === "noshok" ||
      lowercaseManufacturer === "optris" ||
      lowercaseManufacturer === "southern cross" ||
      lowercaseManufacturer === "thermo" ||
      lowercaseManufacturer === "ultra" ||
      lowercaseManufacturer === "pulsar"
    ) {
      let partNumberApply = [postData.product_info.model_number];
      Object.keys(postData.product_info.configurations).forEach(function (key, index) {
        // console.log(key, index, );
        if (postData.product_info.configurations[key].indexOf(":") !== -1)
          partNumberApply.push(postData.product_info.configurations[key].split(":")[0]);

        // key: the name of the object key
        // index: the ordinal position of the key within the object 
      });
      postData.product_info.model_number = partNumberApply.join('-');
    }
    // console.log(postData.product_info.model_number);
    // return;
    $(this).find(".fa-cog").fadeIn();
    let thisReference = $(this);

    $.post($(this).data("ajaxurl"), postData, (res) => {
      let response = JSON.parse(res);
      //   console.log(response);
      thisReference.find(".fa-cog").fadeOut();
      if (response.success) {
        // $('#added-to-cart').addClass("open");
        $('section.product-post > .container').prepend('<div class="alert alert-primary fade-in-cart-added-alert fadeIn mb-3" role="alert">Sucessfully added to cart.</div>');
        $('html, body').animate({
          scrollTop: $('section.product-post > .container').offset().top - 100
        }, 1000);
        // $('#config-accordion .card-body.before-added').fadeOut();
        // $('#config-accordion .card-body.added-to-cart').fadeIn();
        $(this).prop('disabled', false);
      } else {
        // console.log(response.error);
      }
    });
  });
  //Ajax Call End
})(jQuery);
