(function ($) {
  const testimonials_slider = $(".testimonials-slider");
  if (!testimonials_slider.length) return;
  testimonials_slider.slick({
    infinite: true,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    // adaptiveHeight: true
  });
})(jQuery);
